import { FC } from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  FileField,
  FunctionField,
  ImageField,
  NumberField,
  Tab,
  TabbedShowLayout,
  TextField,
  WrapperField,
} from 'react-admin';
import {
  AdminTherapistProfileUpdateResponseDto,
  AdminTherapistResponseDto,
  SupervisionRequestResponseDtoTypeEnum,
  TherapistAdditionalEducationResponseDto,
  TherapistMainEducationResponseDto,
} from 'shared/api';
import { LABELS } from 'shared/constants/labels';
import { getLanguages } from 'shared/utils/get-languages';
import { getGender } from 'shared/utils/get-gender';
import { getReligions } from 'shared/utils/get-religions';
import { getSupervisionType } from 'shared/utils/get-supervision-type';
import { getSpecializations } from 'shared/utils/get-specialization';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import { API_URL } from 'shared/constants/api-url';
import { Loading } from 'react-admin';
import { differenceBetweenObjects } from 'shared/utils/difference-between-objects';
import { getUpdateColorByKey } from 'shared/utils/get-update-color-by-key';
import { getValueFromTextareaWithLineBreak } from 'shared/utils/get-value-from-textarea-with-line-break';
import { getWorkApproach } from 'shared/utils/get-work-approach';

interface Props {
  data: AdminTherapistProfileUpdateResponseDto;
}
function getKeyByValue(object: Record<string, any>, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}
export const TherapistsProfileUpdatesShowContent: FC<Props> = ({ data }) => {
  const { data: therapistData, isLoading } =
    useHttpClient<AdminTherapistResponseDto>(
      API_URL.therapistById(data.therapistId),
    );

  if (isLoading) {
    return <Loading />;
  }

  if (!therapistData) return null;
  if (!data) return null;

  const therapistUpdateData = {
    ...data.update,
  };

  const slicedTherapistData = {
    educationInfo: therapistData.educationInfo,
    personalInfo: therapistData.personalInfo,
    recommendationInfo: therapistData.recommendationInfo,
    supervisionInfo: therapistData.supervisionInfo,
    therapiesInfo: therapistData.therapiesInfo,
    workingInfo: therapistData.workingInfo,
  };

  const difference = differenceBetweenObjects(
    slicedTherapistData,
    therapistUpdateData,
  );

  const getColor = (key: string) => getUpdateColorByKey(difference, key);

  return (
    <TabbedShowLayout>
      <Tab label="Основная информация">
        <TextField
          color={getColor('firstname')}
          source="update.personalInfo.firstname"
          label={LABELS.firstname}
        />
        <TextField
          color={getColor('lastname')}
          source="update.personalInfo.lastname"
          label={LABELS.lastname}
        />
        <TextField
          color={getColor('country')}
          source="update.personalInfo.country"
          label={LABELS.country}
        />
        <TextField
          color={getColor('city')}
          source="update.personalInfo.city"
          label={LABELS.city}
        />
        <FunctionField
          color={getColor('gender')}
          label={LABELS.gender}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            getGender(record.update.personalInfo.gender)
          }
        />
        <FunctionField
          color={getColor('about')}
          label={LABELS.shortAbout}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            getValueFromTextareaWithLineBreak(record.update.personalInfo.shortAbout)
          }
        />
        <FunctionField
          color={getColor('about')}
          label={LABELS.about}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            getValueFromTextareaWithLineBreak(record.update.personalInfo.about)
          }
        />

        <WrapperField label={LABELS.avatarKey}>
          {data?.update.personalInfo.avatarFilekey && (
            <a href={data?.update.personalInfo.avatarFilekey} target="_blank" rel="noreferrer">
              <ImageField
                source="update.personalInfo.avatarFilekey"
                sx={{
                  '& .RaImageField-image': {
                    width: 300,
                    height: 300,
                    objectFit: 'cover',
                  },
                }}
              />
            </a>
          )}
        </WrapperField>
        {data?.update.personalInfo.videoFilekey && (
          <WrapperField label="Видео-презентация">
            <video
              src={data?.update.personalInfo.videoFilekey}
              controls
              playsInline
            />
          </WrapperField>
        )}
      </Tab>
      <Tab label="Опыт работы">
        <FunctionField
          color={getColor('workApproach1')}
          label={LABELS.workApproach1}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            getWorkApproach(record.update?.workingInfo?.workApproach1)
          }
        />
        <FunctionField
          color={getColor('workApproach2')}
          label={LABELS.workApproach2}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            getWorkApproach(record.update?.workingInfo?.workApproach2)
          }
        />
        <FunctionField
          color={getColor('workApproach3')}
          label={LABELS.workApproach3}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            getWorkApproach(record.update?.workingInfo?.workApproach3)
          }
        />
        <NumberField
          color={getColor('price')}
          source="update.workingInfo.price"
          label={LABELS.price}
        />
        <FunctionField
          color={getColor('languages')}
          label={LABELS.languages}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            getLanguages(record.update.workingInfo.languages)
          }
        />
        {data?.update.workingInfo.workWithReligions && (
          <FunctionField
            color={getColor('workWithReligions')}
            label={LABELS.workWithReligions}
            render={(record: AdminTherapistProfileUpdateResponseDto) =>
              record?.update.workingInfo.workWithReligions &&
              getReligions(record?.update.workingInfo.workWithReligions)
            }
          />
        )}

        <NumberField
          color={getColor('devoteHoursToPlatform')}
          source="update.workingInfo.devoteHoursToPlatform"
          label={LABELS.devoteHoursToPlatform}
        />
        <NumberField
          color={getColor('numberOfPatients')}
          source="update.workingInfo.numberOfPatients"
          label={LABELS.numberOfPatients}
        />
        <BooleanField
          color={getColor('workWithIndividual')}
          source="update.workingInfo.workWithIndividual"
          label={LABELS.workWithIndividual}
        />
        <BooleanField
          color={getColor('workWithClinical')}
          source="update.workingInfo.workWithClinical"
          label={LABELS.workWithClinical}
        />
        <BooleanField
          color={getColor('workWithCheckup')}
          source="update.workingInfo.workWithCheckup"
          label={LABELS.workWithCheckup}
        />
        <BooleanField
          color={getColor('workWithCouples')}
          source="update.workingInfo.workWithCouples"
          label={LABELS.workWithCouples}
        />
        <BooleanField
          color={getColor('workWithFamilies')}
          source="update.workingInfo.workWithFamilies"
          label={LABELS.workWithFamilies}
        />
        <BooleanField
          color={getColor('workWithGroups')}
          source="update.workingInfo.workWithGroups"
          label={LABELS.workWithGroups}
        />
        <BooleanField
          color={getColor('workWithChildren')}
          source="update.workingInfo.workWithChildren"
          label={LABELS.workWithChildren}
        />
        <BooleanField
          color={getColor('workWithLgbt')}
          source="update.workingInfo.workWithLgbt"
          label={LABELS.workWithLgbt}
        />
        <NumberField
          color={getColor('workingExperience')}
          source="update.workingInfo.workingExperience"
          label={LABELS.workingExperience}
        />
        <FunctionField
          color={getColor('workingExperienceDescription')}
          label={LABELS.workingExperienceDescription}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            getValueFromTextareaWithLineBreak(
              record.update.workingInfo.workingExperienceDescription,
            )
          }
        />
        <FunctionField
          color={getColor('ethnicExperienceDescription')}
          label={LABELS.ethnicExperienceDescription}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            getValueFromTextareaWithLineBreak(
              record.update.workingInfo.ethnicExperienceDescription,
            )
          }
        />
      </Tab>
      <Tab label="Образование">
        <ArrayField
          source="update.educationInfo.education"
          label="Высшее образование"
        >
          <Datagrid
            bulkActionButtons={false}
            rowStyle={(record: TherapistMainEducationResponseDto) => {
              const newValue =
                difference.educationInfo &&
                difference.educationInfo.education &&
                difference.educationInfo.education
                  .map(
                    (item: any) =>
                      getKeyByValue(item, record.university) !== undefined,
                  )
                  .some((item: any) => item);

              return {
                backgroundColor: newValue && getColor('education'),
              };
            }}
          >
            <TextField source="university" label={LABELS.university} />
            <TextField source="speciality" label={LABELS.speciality} />
            <FileField
              source="files"
              src="key"
              title="filename"
              target="_blank"
              label={LABELS.files}
            />
          </Datagrid>
        </ArrayField>

        <ArrayField
          source="update.educationInfo.additionalEducation"
          label="Дополнительное образование"
        >
          <Datagrid
            bulkActionButtons={false}
            rowStyle={(record: TherapistAdditionalEducationResponseDto) => {
              const newValue =
                difference.educationInfo &&
                difference.educationInfo.additionalEducation &&
                difference.educationInfo.additionalEducation
                  .map(
                    (item: any) =>
                      getKeyByValue(item, record.organisation) !== undefined,
                  )
                  .some((item: any) => item);

              return {
                backgroundColor: newValue && getColor('additionalEducation'),
              };
            }}
          >
            <TextField source="organisation" label={LABELS.organisation} />
            <TextField source="course" label={LABELS.course} />
            <FileField
              source="files"
              src="key"
              title="filename"
              target="_blank"
              label={LABELS.files}
            />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="Супервизии">
        <BooleanField
          color={getColor('isSupervisor')}
          source="update.supervisionInfo.isSupervisor"
          label={LABELS.isSupervisor}
        />
        {data?.update.supervisionInfo.supervisionExperience && (
          <NumberField
            color={getColor('supervisionExperience')}
            source="update.supervisionInfo.supervisionExperience"
            label={LABELS.supervisionExperience}
          />
        )}

        {data?.update.supervisionInfo.therapistSupervisionInfo &&
          data?.update.supervisionInfo.therapistSupervisionInfo
            .supervisionFrequency && (
            <TextField
              color={getColor('supervisionFrequency')}
              source="update.supervisionInfo.therapistSupervisionInfo.supervisionFrequency"
              label={LABELS.supervisionFrequency}
            />
          )}
        {data?.update.supervisionInfo.therapistSupervisionInfo &&
          data?.update.supervisionInfo.therapistSupervisionInfo
            .supervisionType && (
            <FunctionField
              color={getColor('supervisionType')}
              label={LABELS.supervisionType}
              render={(record: AdminTherapistProfileUpdateResponseDto) =>
                getSupervisionType(
                  record.update.supervisionInfo.therapistSupervisionInfo
                    .supervisionType as SupervisionRequestResponseDtoTypeEnum[],
                )
              }
            />
          )}
        {data?.update.supervisionInfo.therapistSupervisionInfo &&
          data?.update.supervisionInfo.therapistSupervisionInfo
            .supervisionVisitsDuration && (
            <NumberField
              color={getColor('supervisionVisitsDuration')}
              source="update.supervisionInfo.therapistSupervisionInfo.supervisionVisitsDuration"
              label={LABELS.supervisionVisitsDuration}
            />
          )}
        {data?.update.supervisionInfo.therapistSupervisionInfo &&
          data?.update.supervisionInfo.therapistSupervisionInfo
            .supervisionExperienceDescription && (
            <FunctionField
              color={getColor('supervisionExperienceDescription')}
              label={LABELS.supervisionExperienceDescription}
              render={(record: AdminTherapistProfileUpdateResponseDto) =>
                getValueFromTextareaWithLineBreak(
                  record.update.supervisionInfo.therapistSupervisionInfo
                    .supervisionExperienceDescription,
                )
              }
            />
          )}
      </Tab>
      <Tab label="Терапия">
        <FunctionField
          color={getColor('specialisation')}
          label={LABELS.specialisation}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            record.update.therapiesInfo.specialisation
              .map((item) => item && getSpecializations(item))
              .join(', ')
          }
        />
        <FunctionField
          color={getColor('diseases')}
          label={LABELS.therapistProfileDiseases}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            record.update.therapiesInfo.diseases
              .map((item) => (item ? item.name : item))
              .join(', ')
          }
        />
        <FunctionField
          color={getColor('excludedDiseases')}
          label={LABELS.therapistProfileExcludedDiseases}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            record.update.therapiesInfo.excludedDiseases
              .join(', ')
          }
        />
        <FunctionField
          color={getColor('therapyMethods')}
          label={LABELS.therapistProfileTherapyMethods}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            record.update.therapiesInfo.therapyMethods
              .map((item) => (item ? item.name : item))
              .join(', ')
          }
        />
        <BooleanField
          color={getColor('workWithPsychiatristExperience')}
          source="update.therapiesInfo.workWithPsychiatristExperience"
          label={LABELS.workWithPsychiatristExperience}
        />
        <FunctionField
          color={getColor('psychiatristCollaboration')}
          label={LABELS.psychiatristCollaboration}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>
            getValueFromTextareaWithLineBreak(record.update.therapiesInfo.psychiatristCollaboration)
          }
        />
      </Tab>
      <Tab label="Медийность">
        <BooleanField
          color={getColor('workshopExperience')}
          source="update.collaborationInfo.workshopExperience"
          label={LABELS.workshopExperience}
        />
        <BooleanField
          color={getColor('workshopDesire')}
          source="update.collaborationInfo.workshopDesire"
          label={LABELS.workshopDesire}
        />
        <BooleanField
          color={getColor('blogMaterials')}
          source="update.collaborationInfo.blogMaterials"
          label={LABELS.blogMaterials}
        />
        <BooleanField
          color={getColor('specialProjects')}
          source="update.collaborationInfo.specialProjects"
          label={LABELS.specialProjects}
        />
        <FunctionField
          color={getColor('socialsLinks')}
          label={LABELS.socialsLinks}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>

            record.update?.collaborationInfo?.socialsLinks?.length && record.update?.collaborationInfo.socialsLinks.map(( el:string, key) => <div key={key}>{el}</div> )
          }
        />
        <BooleanField
          color={getColor('promotionSupport')}
          source="update.collaborationInfo.promotionSupport"
          label={LABELS.promotionSupport}
        />
        <FunctionField
          color={getColor('materialsLinks')}
          label={LABELS.materialsLinks}
          render={(record: AdminTherapistProfileUpdateResponseDto) =>

            record.update?.collaborationInfo?.materialsLinks?.length && record.update?.collaborationInfo.materialsLinks.map(( el:string, key) => <div key={key}>{el}</div> )
          }
        />
      </Tab>
    </TabbedShowLayout>
  );
};
